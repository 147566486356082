import React, {useState} from "react";
import { Trans } from "react-i18next";
import { Icon, Icons } from "../Icon/Icon";
import "./SectionFaq.css";

const faq = [
    {
        title: 'What type of 3D files do you support?',
        text: (
            <p>
                <span>The application can support various types of 3D files:</span>
                <span>Conventional Models: These include files from 3D modelling software like 3DS Max, SketchUp, Blender and more.</span>
                <span>Conventional Materials: The app can handle material files from software like AutoCAD, Vray/Corona, Revit/Octane, Blender, ArchiCAD and others.</span>
                <span>Scenes: The app can support scenes from game engine software like Unreal Engine and architectural visualisation software like Twinmotion 2023.</span>
                <span>Remember, the specific file types will depend on the software used to create the models, materials, or scenes.</span>
            </p>
        ),
    },
    {
        title: 'What if I have my own 3D models?',
        text: (
            <p>
                <span>For those with existing 3D models, our Real Estate 3D solution seamlessly incorporates them. Supported formats include 3DS Max, SketchUp, and Blender.</span>
                <span>We handle model and material conversions as part of the process.</span>
                <span>Additionally, we enhance scenes, offer content creation, and enable customer engagement through CRM and online meetings.</span>
                <span>Your existing assets are fully leveraged, resulting in captivating real estate presentations.</span>
            </p>
        ),
    },
    {
        title: 'What if I don\'t have 3D models created?',
        text: (
            <p>
                <span>If you don't have 3D models, our Real Estate 3D solution offers full-service 3D modelling from scratch.</span>
                <span>Our team can create models to match your vision. We also provide content creation options, including web apps, renders, animations, and tours, ensuring your real estate projects shine, even without existing models.</span>
                <span>Additionally, we provide a range of content creation options, including high-quality 3D web apps, path-traced renders, HQ animations, and 360-degree tours.</span>
                <span>These tools allow you to showcase your real estate projects effectively, even if you don't have pre-existing 3D models.</span>
            </p>
        ),
    },
    {
        title: 'What kind of support do you provide?',
        text: (
            <p>
                <span>We support and improve your product after release.</span>
                <span>We will solve any technical problem on the spot, and by hosting with us, your application is adequately taken care of and secured, and its performance is constantly monitored.</span>
                <span>Supporting and maintaining every part of your application hosting gives you peace of mind, and you don't have to worry about hiring a dedicated IT team to take care of your infrastructure.</span>
            </p>
        ),
    },
    {
        title: 'How long does it take to create?',
        text: (
            <p>
                <span>The estimated production time is around 2-3 months.</span>
                <span>Still, before we start, we discuss in detail all the stages of the work and their exact timeframes with each client so that everything is clear and straightforward at the start of the collaboration.</span>
                <span>A detailed breakdown is presented at the meeting.</span>
            </p>
        ),
    },
    {
        title: 'Can I customise a website interface to match my brand?',
        text: (
            <p>
                <span>We are happy to respond to usability expectations.</span>
                <span>We tailor a user interface to your brand style because we know the value of individual style.</span>
                <span>Our designers analyse your branding guidelines and prepare design previews to suit your needs.</span>
                <span>We prepare resources to be shared later in Figma.</span>
            </p>
        ),
    },
];

const SectionFaq = () => {
    const [selected, setSelected] = useState(-1);

    return (
        <section id="faq" className="faq">
            <h2 className="wow dadeInDown">
                <Trans i18nKey="faq.title">FAQ</Trans>
            </h2>
            <div className="items">
                {faq.map((p, index) => (
                    <div key={index} className="item wow fadeInUp" onClick={() => setSelected(selected !== index ? index : -1)}>
                        <div>
                            <span>
                                <Trans i18nKey={`faq.items.${index}.title`}>{p.title}</Trans>
                            </span>
                            {selected !== index && (
                                <Icon className="plus" icon={Icons.FaqPlus} width={24} height={24} />
                            )}
                            {selected === index && (
                                <Icon className="minus" icon={Icons.FaqMinus} width={24} height={24} />
                            )}
                        </div>
                        {selected === index && <Trans i18nKey={`faq.items.${index}.text`}>{p.text}</Trans>}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default SectionFaq;
