import React from "react";
import { Trans } from "react-i18next";
import { Icon, Icons } from "../Icon/Icon";
import "./SectionAbout.css";

const SectionAbout = () => {
    return (
        <section id="about" className="about">
            <h2 className="wow fadeInDow">
                <span>
                    <Trans i18nKey="about.title">What is</Trans>
                </span>
                <Icon icon={Icons.Logo} width={152} height={40} />
                <span>?</span>
            </h2>
            <p className="description wow fadeInDown">
                <Trans i18nKey="about.description">
                    50k.io Real Estate Sales Management System for working with real estate agencies and directly with
                    private buyers.
                </Trans>
            </p>
            <div className="container">
                <div className="item wow fadeInDown">
                    <div className="content">
                        <h4>
                            <Trans i18nKey="about.items.0.title">Interactive 3D model</Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="about.items.0.description">
                                Stand out from your competitors by showing all the benefits of 3D travel.
                            </Trans>
                        </p>
                        <ul>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.0.checks.0">Gamified 3D model</Trans>
                                </span>
                            </li>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.0.checks.1">Path traced visualisations</Trans>
                                </span>
                            </li>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.0.checks.2">Works on any device</Trans>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <img src="/img/about-interactive-tablet.png" alt="Tablet" />
                    <img src="/img/about-man.png" alt="Man" />
                    <img src="/img/about-programs.png" alt="Programs"/>
                </div>
                <div className="item wow fadeInLeft">
                    <div className="content">
                        <h4>
                            <Trans i18nKey="about.items.1.title">Virtual tours</Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="about.items.1.description">
                                Show your users all the details of the project, including virtual walks around the
                                apartment. Give the user the opportunity to independently furnish their future apartment.
                            </Trans>
                        </p>
                        <ul>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.1.checks.0">3D plans</Trans>
                                </span>
                            </li>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.1.checks.1">Virtual walk</Trans>
                                </span>
                            </li>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.1.checks.2">360 views</Trans>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <img src="/img/about-virtual-tablet.png" alt="Virtual tours" />
                </div>
                <div className="item wow fadeInUp">
                    <div className="content">
                        <h4>
                            <Trans i18nKey="about.items.2.title">Complex digital platform</Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="about.items.2.description">
                                Observe user actions and communicate with them by using 50k.io. Integrate with any of your
                                software systems. Show buyers all the benefits of working with you on one site.
                            </Trans>
                        </p>
                        <ul>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.2.checks.0">CRM</Trans>
                                </span>
                            </li>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.2.checks.1">CMS</Trans>
                                </span>
                            </li>
                            <li>
                                <Icon icon={Icons.AboutCheck} width={15} height={14}/>
                                <span>
                                    <Trans i18nKey="about.items.2.checks.2">Task Management system</Trans>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <img src="/img/about-complex-tablet.png" alt="Complex digital platform" />
                </div>
                <div className="item wow fadeInRight">
                    <img src="/img/about-technologies.png" alt="Technologies" />
                    <img src="/img/about-programs-mobile.png" alt="Programs Mobile" />
                    <img src="/img/about-technologies-mobile.png" alt="Technologies Mobile" />
                </div>
            </div>
        </section>
    );
}

export default SectionAbout;