import React from "react";
import { Trans } from "react-i18next";
import { Icon, Icons } from "../Icon/Icon";
import "./SectionBenefits.css";

const SectionBenefits = () => {
    return (
        <section id="benefits" className="benefits">
            <div className="block wow fadeInLeft">
                <h2>
                    <Trans i18nKey="benefits.title">Benefits for customers & companies</Trans>
                </h2>
            </div>
            <div className="block wow fadeInRight">
                <Icon icon={Icons.BenefitsSatisfaction} width={42} height={42}/>
                <h4>
                    <Trans i18nKey="benefits.items.0.title">The best moment to invest</Trans>
                </h4>
                <p>
                    <Trans i18nKey="benefits.items.0.description">
                        Buying an apartment from a reliable developer during the construction phase at the lowest price
                    </Trans>
                </p>
            </div>
            <div className="block wow fadeInLeft">
                <Icon icon={Icons.BenefitsEducation} width={42} height={42}/>
                <h4>
                    <Trans i18nKey="benefits.items.1.title">High effectiveness</Trans>
                </h4>
                <p>
                    <Trans i18nKey="benefits.items.1.description">
                        Clients receive the most complete information about the property before meeting with the agent
                    </Trans>
                </p>
            </div>
            <div className="block wow fadeIn">
                <Icon icon={Icons.BenefitsBrand} width={42} height={42}/>
                <h4>
                    <Trans i18nKey="benefits.items.2.title">Brand awareness</Trans>
                </h4>
                <p>
                    <Trans i18nKey="benefits.items.2.description">
                        High quality marketing content enhances a business image
                    </Trans>
                </p>
            </div>
            <div className="block wow fadeInRight">
                <Icon icon={Icons.BenefitsDevices} width={42} height={42}/>
                <h4>
                    <Trans i18nKey="benefits.items.3.title">Accessibility & Usability</Trans>
                </h4>
                <p>
                    <Trans i18nKey="benefits.items.3.description">
                        User-friendly and versatile. Access to the platform from any device
                    </Trans>
                </p>
            </div>
            <div className="block wow fadeInLeft">
                <Icon icon={Icons.BenefitsVisualisation} width={42} height={42}/>
                <h4>
                    <Trans i18nKey="benefits.items.4.title">State-of-the-art visualisations</Trans>
                </h4>
                <p>
                    <Trans i18nKey="benefits.items.4.description">
                        The most advanced graphics engine (Unreal Engine) is the easiest way to bring your visualisation
                        to the next level
                    </Trans>
                </p>
            </div>
            <div className="block wow fadeInRight">
                <Icon icon={Icons.BenefitsConversion} width={42} height={42}/>
                <h4>
                    <Trans i18nKey="benefits.items.5.title">High lead conversion</Trans>
                </h4>
                <p>
                    <Trans i18nKey="benefits.items.5.description">
                        High lead conversion demonstrates the platform's effectiveness in converting visitors into
                        customers. It successfully engages users, encouraging actions like purchases or signing for
                        services
                    </Trans>
                </p>
            </div>
        </section>
    );
}

export default SectionBenefits;
