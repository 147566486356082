import React from "react";
import clsx from "clsx";
import "./Modal.css";

interface IModal {
    className?: string;
    children: React.ReactNode;
    onClose: () => void;
}
const Modal = (props: IModal) => {
    return (
        <div className={clsx('overlay', props.className)} onClick={() => props.onClose()}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                {props.children}
            </div>
        </div>
    );
};

export default Modal;