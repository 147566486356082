import React, {useState} from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Icon, Icons } from "../Icon/Icon";
import { i18nCurrency } from "../../i18n";
import "./SectionPricing.css";

const SectionPricing = () => {
    const navigate = useNavigate();
    const [tab, setTab] = useState<number>(0);

    const data: any = [
        [
            {
                title: 'Single house',
                description: 'For those who have one unique house and own 3D model',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': false,
                    'Video scripts': true,
                    '1x house (8 points)': true,
                    '1x Interior (8 points)': true,
                    'Given environment': true,
                    'AI-assistant': false,
                    'Integrate with another soft': false,
                    'Streaming platform': false,
                },
                isModel: false,
                price: i18nCurrency(3060),
            },
            {
                title: 'Multiple house',
                description: 'For those who have a multiple unique house and own 3D models',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': false,
                    'Video scripts': true,
                    '2x house (12 points)': true,
                    '2x Interior (16 points)': true,
                    'Given environment': true,
                    'AI-assistant': false,
                    'Integrate with another soft': false,
                    'Streaming platform': false,
                },
                isModel: false,
                price: i18nCurrency(4570),
            },
            {
                title: 'Apartment complex',
                description: 'For those who have a complex of multiple apartments and own 3D models',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': false,
                    'Video scripts': true,
                    '2x house (18 points)': true,
                    '5x Interior (40 points)': true,
                    'Given environment': true,
                    'AI-assistant': false,
                    'Integrate with another soft': true,
                    'Streaming platform': false,
                },
                isModel: false,
                price: i18nCurrency(11380),
            }
        ],
        [
            {
                title: 'Single house',
                description: 'For those who have one unique house and need 3D model development',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': true,
                    'Video scripts': true,
                    '1x house (8 points)': true,
                    '1x Interior (8 points)': true,
                    'Advanced environment': true,
                    'AI-assistant': true,
                    'Integrate with another soft': false,
                    'Streaming platform': false,
                },
                isModel: true,
                price: i18nCurrency(4600),
            },
            {
                title: 'Multiple house',
                description: 'For those who have a multiple unique houses and need 3D development',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': true,
                    'Video scripts': true,
                    '2x house (12 points)': true,
                    '2x Interior (16 points)': true,
                    'Advanced environment': true,
                    'AI-assistant': true,
                    'Integrate with another soft': false,
                    'Streaming platform': false,
                },
                isModel: true,
                price: i18nCurrency(9200),
            },
            {
                title: 'Apartment complex',
                description: 'For those who have complex of a multiple apartments and need 3D development',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': true,
                    'Video scripts': true,
                    '2x house (18 points)': true,
                    '5x Interior (40 points)': true,
                    'Advanced environment': true,
                    'AI-assistant': true,
                    'Integrate with another soft': true,
                    'Streaming platform': false,
                },
                isModel: true,
                price: i18nCurrency(25700),
            }
        ],
        [
            {
                title: 'Single house',
                description: 'For those who have one unique house and need 3D model development',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': true,
                    'Video scripts': true,
                    '1x house': true,
                    '1x Interior': true,
                    'Advanced environment': true,
                    'AI-assistant': true,
                    'Integrate with another soft': true,
                    'Streaming platform': true,
                },
                isModel: true,
                price: i18nCurrency(12800),
            },
            {
                title: 'Multiple house',
                description: 'For those who have a multiple unique houses and need 3D development',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': true,
                    'Video scripts': true,
                    '2x house': true,
                    '2x Interior': true,
                    'Advanced environment': true,
                    'AI-assistant': true,
                    'Integrate with another soft': true,
                    'Streaming platform': true,
                },
                isModel: true,
                price: i18nCurrency(25600),
            },
            {
                title: 'Apartment complex',
                description: 'For those who have complex of a multiple apartments and need 3D development',
                items: {
                    '3D web-site': true,
                    '3D modeling (UE 5)': true,
                    'Video scripts': true,
                    '2x house': true,
                    '5x Interior': true,
                    'Advanced environment': true,
                    'AI-assistant': true,
                    'Integrate with another soft': true,
                    'Streaming platform': true,
                },
                isModel: true,
                price: i18nCurrency(37700),
            }
        ],
    ];

    const scrollToAnchor = (anchor: string, location: string | null = '/') => {
        if (location) {
            navigate(location);
        }

        setTimeout(() => {
            document.body.className = '';
            window.scrollTo(0, document.getElementById(anchor)!.offsetTop - 106);
        }, 300);
    };

    return (
        <section id="pricing" className="pricing">
            <h2 className="wow fadeInDown">
                <Trans i18nKey="pricing.title">Pricing</Trans>
            </h2>
            <p className="description wow fadeInDown">
                <Trans i18nKey="pricing.description">
                    Knowing the diverse customer needs, we’ve prepared precisely laid out pricing plans for each option,
                    as well as the possibility of custom project pricing
                </Trans>
            </p>
            <nav aria-label="Tabs" className="wow fadeInDown">
                <span
                    className={tab === 0 ? 'active' : ''}
                    onClick={() => setTab(0)}
                >
                    <Trans i18nKey="pricing.tabs.0.title">Basic (w/o 3D)</Trans>
                </span>
                <span
                    className={tab === 1 ? 'active' : ''}
                    onClick={() => setTab(1)}
                >
                    <Trans i18nKey="pricing.tabs.1.title">Basic (with 3D)</Trans>
                </span>
                <span
                    className={tab === 2 ? 'active' : ''}
                    onClick={() => setTab(2)}
                >
                    <Trans i18nKey="pricing.tabs.2.title">Advanced (with 3D)</Trans>
                </span>
            </nav>
            <div className="container">
                {data[tab].map((item: any, index: number) => (
                    <div key={index} className={`item wow fadeIn${index === 0 ? 'Left' : index === 1 ? '' : 'Right'}`}>
                        <h4>
                            <Trans i18nKey={`pricing.tabs.${tab}.items.${index}.title`}>{item.title}</Trans>
                        </h4>
                        <p className="item-description">
                            <Trans i18nKey={`pricing.tabs.${tab}.items.${index}.description`}>{item.description}</Trans>
                        </p>
                        <ul>
                            {Object.keys(item.items).map((feature, fIndex) => (
                                <li key={fIndex} className={!item.items[feature] ? 'disabled' : ''}>
                                    <Icon icon={Icons.PricingStar} width={11} height="100%" />
                                    <span>
                                        <Trans i18nKey={`pricing.tabs.${tab}.items.${index}.features.${fIndex}`}>{feature}</Trans>
                                    </span>
                                </li>
                            ))}
                        </ul>
                        <div className="price">
                            <div>
                                <span>
                                    <Trans i18nKey="pricing.from">From</Trans>
                                </span>
                                <b><Trans i18nKey="currency.symbol">$</Trans> {item.price}</b>
                            </div>
                            {item.isModel && (
                                <p>
                                    <Trans i18nKey="pricing.withDevelopment">With 3D Development</Trans>
                                </p>
                            )}
                            {!item.isModel && (
                                <p>
                                    <Trans i18nKey="pricing.withoutDevelopment">Without 3D Development</Trans>
                                </p>
                            )}
                        </div>
                        <button className="white" onClick={() => scrollToAnchor('contact')}>
                            <Trans i18nKey="pricing.button">Book a call</Trans>
                        </button>
                    </div>
                ))}
            </div>
            <div className="calculator wow fadeInUp">
                <div className="content">
                    <div className="title">
                        <h3>
                            <Trans i18nKey="pricing.calculator.title">Calculate custom price</Trans>
                        </h3>
                        <div>
                            <Icon icon={Icons.PricingLightning} width={17} height="100%" />
                            <span>
                                <Trans i18nKey="pricing.calculator.tag">Individual pricing project</Trans>
                            </span>
                        </div>
                    </div>
                    <p>
                        <Trans i18nKey="pricing.calculator.description">
                            Knowing the diverse customer needs, we’ve prepared precisely laid out pricing plans for each
                            option, as well as the possibility of custom project pricing
                        </Trans>
                    </p>
                    <button className="white" onClick={() => scrollToAnchor('calculator', '/calculator')}>
                        <span>
                            <Trans i18nKey="pricing.calculator.button">See calculator</Trans>
                        </span>
                        <Icon icon={Icons.PricingArrow} width={40} height="100%"/>
                    </button>
                </div>
                <img src="/img/calculator.png" alt="Calculator"/>
            </div>
        </section>
    );
}

export default SectionPricing;
