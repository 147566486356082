import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Lottie from "lottie-web";
import { useTranslation } from "react-i18next";
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { initCourses } from "./i18n";
import Main from "./containers/Main";
import Calculator from "./containers/Calculator";
import Unity from "./containers/Unity";
import ChatBot from "./containers/ChatBot";
import AiChat from "./containers/AiChat/AiChat";
import Cookie from "./components/Cookie/Cookie";
import "./App.css";

function App() {
    const loaderRef = useRef(null);
    const { i18n } = useTranslation();

    const [loaderState, setLoaderState] = useState(0);

    const onChangeLanguage = (language: string) => i18n.changeLanguage(language);

    useEffect(() => {
        const animation = Lottie.loadAnimation({
            container: loaderRef.current! as Element,
            path: '/loader.json',
            loop: false,
        });

        animation.setSpeed(1.6);
        animation.addEventListener('complete', () => {
            setLoaderState(1);

            setTimeout(() => {
                setLoaderState(2);
                document.body.className = '';
            }, 1000);
        });
    }, []);
    useEffect(() => {
        (async () => {
            await initCourses();
        })();
    }, []);

    return (
        <div className="app">
            <AiChat
                chatUrl="https://chat.50k.io"
                projectId="0429d55e-4d08-4eef-b530-8569e0896e5b"
                initialMessage="Write welcome message"
            />
            <div id="loader" ref={loaderRef} className={clsx(
                loaderState > 0 ? 'opacity' : '',
                loaderState > 1 ? 'hidden' : '',
            )} />
            <RouterProvider router={createBrowserRouter(createRoutesFromElements(
                <React.Fragment>
                    <Route path="*" element={<Main onChangeLanguage={onChangeLanguage} />} />
                    <Route path="/calculator" element={<Calculator onChangeLanguage={onChangeLanguage} />} />
                    <Route path="/unity" element={<Unity />} />
                    <Route path="/chat-bot" element={<ChatBot onChangeLanguage={onChangeLanguage} />} />
                </React.Fragment>
            ))}/>
            <Cookie />
        </div>
    );
}

export default App;
