import React, {useEffect} from "react";

const Unity = () => {
    useEffect(() => {
        const container: HTMLElement | null = document.querySelector("#unity-container");
        const canvas: HTMLElement | null = document.querySelector("#unity-canvas");

        const loadingBar: HTMLElement | null = document.querySelector("#unity-loading-bar");
        const progressBarFull: HTMLElement | null = document.querySelector("#unity-progress-bar-full");

        const fullscreenButton: HTMLElement | null = document.querySelector("#unity-fullscreen-button");
        const warningBanner: HTMLElement | null = document.querySelector("#unity-warning");

        const unityShowBanner = (msg: string, type: string) => {
            const updateBannerVisibility = () => {
                warningBanner!.style.display = warningBanner!.children.length ? 'block' : 'none';
            };

            const div: HTMLDivElement = document.createElement('div');
            div.innerHTML = msg;

            warningBanner!.appendChild(div);

            if (type === 'error') {
                // @ts-ignore
                div.style = 'background: red; padding: 10px;';
            } else {
                if (type === 'warning') {
                    // @ts-ignore
                    div.style = 'background: yellow; padding: 10px;';
                }

                setTimeout(function() {
                    warningBanner!.removeChild(div);
                    updateBannerVisibility();
                }, 5000);
            }

            updateBannerVisibility();
        }

        const buildUrl = "/unity-build/Build";
        const loaderUrl = buildUrl + "/UnrealToUnityBuild.loader.js";

        const config = {
            dataUrl: buildUrl + "/UnrealToUnityBuild.data.unityweb",
            frameworkUrl: buildUrl + "/UnrealToUnityBuild.framework.js.unityweb",
            codeUrl: buildUrl + "/UnrealToUnityBuild.wasm.unityweb",
            streamingAssetsUrl: "StreamingAssets",
            companyName: "DefaultCompany",
            productName: "Web Project",
            productVersion: "0.1",
            showBanner: unityShowBanner,
        };

        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            const meta = document.createElement('meta');

            meta.name = 'viewport';
            meta.content = 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';

            document.getElementsByTagName('head')[0].appendChild(meta);

            container!.className = "unity-mobile";
            canvas!.className = "unity-mobile";
        } else {
            canvas!.style.width = "960px";
            canvas!.style.height = "600px";
        }

        loadingBar!.style.display = "block";

        const script = document.createElement("script");

        script.src = loaderUrl;
        script.onload = () => {
            // @ts-ignore
            createUnityInstance(canvas, config, (progress: any) => {
                progressBarFull!.style.width = 100 * progress + "%";
            }).then((unityInstance: any) => {
                loadingBar!.style.display = "none";
                fullscreenButton!.onclick = () => {
                    unityInstance.SetFullscreen(1);
                };
            }).catch((message: string) => {
                alert(message);
            });
        };

        document.body.appendChild(script);
    }, []);

    return (
        <div id="unity-container" className="unity-desktop">
            <canvas id="unity-canvas" width={960} height={600} tabIndex={-1}></canvas>
            <div id="unity-loading-bar">
                <div id="unity-logo"></div>
                <div id="unity-progress-bar-empty">
                    <div id="unity-progress-bar-full"></div>
                </div>
            </div>
            <div id="unity-warning"></div>
            <div id="unity-footer">
                <div id="unity-webgl-logo"></div>
                <div id="unity-fullscreen-button"></div>
                <div id="unity-build-title">Web Project</div>
            </div>
        </div>
    );
}

export default Unity;
