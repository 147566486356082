import React, { ReactElement } from "react";
import "./Section.css";

interface SectionProps {
    children: ReactElement;
}
const Section = (props: SectionProps) => {
    return (
        <section className="section">
            {props.children}
        </section>
    );
}

export default Section;
