import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Icon, Icons } from "../../components/Icon/Icon";
import "./Header.css";

const Header = ({ onChangeLanguage }: { onChangeLanguage: (language: string) => void }) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const [isMenu, setIsMenu] = React.useState(false);

    const toggleMenu = () => {
        if (isMenu) {
            setIsMenu(false);
            document.body.className = '';
        } else {
            setIsMenu(true);
            document.body.className = 'hidden';
        }
    };

    const scrollToAnchor = (anchor: string, location: string | null = '/') => {
        if (location) {
            navigate(location);
        }

        setTimeout(() => {
            setIsMenu(false);
            document.body.className = '';
            window.scrollTo(0, document.getElementById(anchor)!.offsetTop - 106);
        }, 300);
    };

    return (
        <header className={isMenu ? 'open' : ''}>
            <div className="logo" onClick={() => scrollToAnchor('promo')}>
                <Icon icon={Icons.Logo} width={69} height={18} />
            </div>
            <nav aria-label="Menu">
                <ul>
                    <li onClick={() => scrollToAnchor('about')}>
                        <Trans i18nKey="header.about">About</Trans>
                    </li>
                    <li className="mobile" onClick={() => scrollToAnchor('benefits')}>
                        <Trans i18nKey="header.benefits">Benefits</Trans>
                    </li>
                    <li className="mobile" onClick={() => scrollToAnchor('features')}>
                        <Trans i18nKey="header.features">Features</Trans>
                    </li>
                    <li onClick={() => scrollToAnchor('pricing')}>
                        <Trans i18nKey="header.pricing">Pricing</Trans>
                    </li>
                    <li onClick={() => scrollToAnchor('faq')}>
                        <Trans i18nKey="header.faq">FAQ</Trans>
                    </li>
                    {/*<li onClick={() => scrollToAnchor('blog')}>Blog</li>*/}
                    <li onClick={() => scrollToAnchor('calculator', '/calculator')}>
                        <Trans i18nKey="header.calculator">Calculator</Trans>
                    </li>
                    <li className="mobile" onClick={() => scrollToAnchor('contact', null)}>
                        <Trans i18nKey="header.contact">Contact</Trans>
                    </li>
                    <li>
                        <select
                            value={i18n.language}
                            onChange={(e) => onChangeLanguage(e.target.value)}
                        >
                            <option value="en">EN</option>
                            <option value="ru">RU</option>
                            <option value="kz">KZ</option>
                            <option value="tr">TR</option>
                        </select>
                    </li>
                </ul>
            </nav>
            <div className="menu-burger" onClick={toggleMenu}>
                <Icon icon={Icons.MenuBurger} width={32} height={32} />
            </div>
            <div className="menu-close" onClick={toggleMenu}>
                <Icon icon={Icons.MenuClose} width={32} height={32} />
            </div>
        </header>
    );
}

export default Header;
