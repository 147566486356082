import React from "react";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import Modal from "../Modal/Modal";
import { Icon, Icons } from "../Icon/Icon";
import "./SectionCalculator.css";
import {i18nCurrency} from "../../i18n";

const SectionCalculator = () => {
    const { t } = useTranslation();

    const [isLock, setIsLock] = React.useState(localStorage.getItem('price') !== '1');
    const [lockModal, setLockModal] = React.useState(false);

    const [buildingInvestment, setBuildingInvestment] = React.useState<number>(0);
    const [buildingOverall, setBuildingOverall] = React.useState<number>(2);
    const [buildingUnique, setBuildingUnique] = React.useState<number>(1);
    const [buildingModel, setBuildingModel] = React.useState<number>(0);
    const [buildingModelFormat, setBuildingModelFormat] = React.useState<number>(0);
    const [buildingModelDocumentation, setBuildingModelDocumentation] = React.useState<number>(0);
    const [buildingAdditional, setBuildingAdditional] = React.useState<number>(0);

    const [apartmentsModel, setApartmentsModel] = React.useState<number>(0);
    const [apartmentsOverall, setApartmentsOverall] = React.useState<number>(1);
    const [apartmentsUnique, setApartmentsUnique] = React.useState<number>(1);
    const [apartmentsRooms, setApartmentsRooms] = React.useState<number>(1);

    const [environmentModel, setEnvironmentModel] = React.useState<number>(0);
    const [environmentModelFormat, setEnvironmentModelFormat] = React.useState<number>(0);
    const [environmentPlot, setEnvironmentPlot] = React.useState<number>(0);
    const [environmentNeighborhood, setEnvironmentNeighborhood] = React.useState<number>(0);

    const [websiteHighlight, setWebsiteHighlight] = React.useState<boolean>(false);
    const [websiteFiltering, setWebsiteFiltering] = React.useState<number>(0);
    const [websiteAnimation, setWebsiteAnimation] = React.useState<number>(0);
    const [websiteLanguages, setWebsiteLanguages] = React.useState<number>(1);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const name = event.target[0].value;
        const phone = event.target[1].value;
        const email = event.target[2].value;

        const url = 'https://api.telegram.org/bot6795818259:AAG5oLXb9v33iB9RvwfDd0iyp9bR61_hGis/sendMessage?chat_id=-1002007602712&parse_mode=html&text=';
        const message = `<strong>New lead from landing calculator</strong>\n\n<b>Name:</b> ${name}\n<b>Phone:</b> ${phone}\n<b>Email:</b> ${email}`;

        await fetch(url + encodeURIComponent(message));
        setLockModal(false);

        localStorage.setItem('price', '1');
        setIsLock(false);
    };

    const getBuildingsPrice = (format: boolean = true) => {
        let response = 300 * buildingAdditional;

        if (buildingInvestment === 1) {
            response += 450;
        } else if (buildingInvestment === 2) {
            response += (300 * buildingUnique) + (75 * (buildingOverall - buildingUnique));
        } else if (buildingInvestment === 3) {
            response += 750;
        } else if (buildingInvestment === 4) {
            response += (600 * buildingUnique) + (150 * (buildingOverall - buildingUnique));
        }

        if (buildingModel === 1) {
            if (buildingModelFormat === 1) {
                response += 300 * buildingUnique;
            } else if (buildingModelFormat === 2) {
                response += 450 * buildingUnique;
            } else if (buildingModelFormat === 3) {
                response += 600 * buildingUnique;
            }
        } else if (buildingModel === 2) {
            if (buildingModelDocumentation === 1) {
                response += 1350 * buildingUnique;
            } else if (buildingModelDocumentation === 2) {
                response += 1050 * buildingUnique;
            }
        }

        return i18nCurrency(response, format);
    };
    const getApartmentsPrice = (format: boolean = true) => {
        let response = 0;

        if (apartmentsModel === 1) {
            response += (300 * apartmentsRooms * apartmentsUnique) + (15 * apartmentsRooms * (apartmentsOverall - apartmentsUnique));
        }

        return i18nCurrency(response, format);
    };
    const getEnvironmentPrice = (format: boolean = true) => {
        let response = 0;

        if (environmentModel === 1) {
            if (environmentModelFormat === 1) {
                response += 900;
            } else if (environmentModelFormat === 2) {
                response += 1350;
            } else if (environmentModelFormat === 3) {
                response += 1620;
            }
        } else if (environmentModel === 2) {
            response += 300;

            if (environmentPlot === 1) {
                response += 2100;
            } else if (environmentPlot === 2) {
                response += 4200;
            } else if (environmentPlot === 3) {
                response += 6300;
            } else if (environmentPlot === 4) {
                response += 8400;
            }

            if (environmentNeighborhood === 1) {
                response += 300;
            } else if (environmentNeighborhood === 2) {
                response += 2400;
            } else if (environmentNeighborhood === 3) {
                response += 4800;
            } else if (environmentNeighborhood === 4) {
                response += 6000;
            }
        }

        return i18nCurrency(response, format);
    };
    const getWebsitePrice = (format: boolean = true) => {
        let response = 450 * websiteLanguages;

        if (websiteAnimation === 1) {
            response += 1200;
        } else if (websiteAnimation === 2) {
            response += 600;
        }

        if (websiteFiltering > 0) {
            response += 600 + 60 * websiteFiltering;
        }

        return i18nCurrency(response, format);
    };

    return (
        <section id="calculator" className="calculator">
            {lockModal && (
                <Modal className="lock-modal" onClose={() => setLockModal(false)}>
                    <h2>
                        <Trans i18nKey="calculator.modal.title">Get the price</Trans>
                    </h2>
                    <form onSubmit={onSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder={t("calculator.modal.name")}
                            required
                            pattern="[A-Za-z]{1,}"
                            title={t("calculator.modal.nameValidation")}
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder={t("calculator.modal.phone")}
                            required
                            pattern="[0-9]{1,}"
                            title={t("calculator.modal.phoneValidation")}
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder={t("calculator.modal.email")}
                            required
                        />
                        <button type="submit" className="white">
                            <Trans i18nKey="calculator.modal.button">Send</Trans>
                        </button>
                    </form>
                </Modal>
            )}
            <h2 className="wow fadeInDown">
                <Trans i18nKey="calculator.title">Custom price calculator</Trans>
            </h2>
            <p className="description wow fadeInDown">
                <Trans i18nKey="calculator.description">
                    Reduce costs with pre-negotiated, transparent pricing. We don’t charge setup, monthly platform, or per
                    MID fees. There’s no need for you to make any minimum commitments, and we won’t spring any surprises.
                </Trans>
            </p>
            <div className="steps wow fadeInDown">
                <div className="line"></div>
                <div className="step">
                    <i></i>
                    <p>
                        <Trans i18nKey="calculator.steps.0.title">Buildings</Trans>
                    </p>
                    {!isLock && (
                        <span><Trans i18nKey="currency.symbol">$</Trans>{getBuildingsPrice()}</span>
                    )}
                </div>
                <div className="step">
                    <i></i>
                    <p>
                        <Trans i18nKey="calculator.steps.1.title">Apartments</Trans>
                    </p>
                    {!isLock && (
                        <span><Trans i18nKey="currency.symbol">$</Trans>{getApartmentsPrice()}</span>
                    )}
                </div>
                <div className="step">
                    <i></i>
                    <p>
                        <Trans i18nKey="calculator.steps.2.title">Environment</Trans>
                    </p>
                    {!isLock && (
                        <span><Trans i18nKey="currency.symbol">$</Trans>{getEnvironmentPrice()}</span>
                    )}
                </div>
                <div className="step">
                    <i></i>
                    <p>
                        <Trans i18nKey="calculator.steps.3.title">Website</Trans>
                    </p>
                    {!isLock && (
                        <span><Trans i18nKey="currency.symbol">$</Trans>{getWebsitePrice()}</span>
                    )}
                </div>
            </div>
            <div className="buildings wow fadeInLeft">
                <h3>
                    <Trans i18nKey="calculator.steps.0.title">Buildings</Trans>
                </h3>
                <div className="container radio-image">
                    <p className="title">
                        <Trans i18nKey="calcualtor.steps.0.items.0.title">Select investment type</Trans>
                    </p>
                    <div className="radio-image-items">
                        <div
                            className={clsx('radio-image-item', buildingInvestment === 1 ? 'checked' : '')}
                            onClick={() => {
                                setBuildingInvestment(1);
                                setBuildingOverall(2);
                                setBuildingUnique(1);
                            }}
                        >
                            <Icon icon={Icons.CalculatorSingleHouse} width="auto" height={35} />
                            <p>
                                <Trans i18nKey="calculator.steps.0.items.0.items.0">Single houses</Trans>
                            </p>
                        </div>
                        <div
                            className={clsx('radio-image-item', buildingInvestment === 2 ? 'checked' : '')}
                            onClick={() => setBuildingInvestment(2)}
                        >
                            <Icon icon={Icons.CalculatorMultipleHouses} width="auto" height={35} />
                            <p>
                                <Trans i18nKey="calculator.steps.0.items.0.items.1">Multiple houses</Trans>
                            </p>
                        </div>
                        <div
                            className={clsx('radio-image-item', buildingInvestment === 3 ? 'checked' : '')}
                            onClick={() => {
                                setBuildingInvestment(3);
                                setBuildingOverall(2);
                                setBuildingUnique(1);
                            }}
                        >
                            <Icon icon={Icons.CalculatorSingleApartments} width="auto" height={35} />
                            <p>
                                <Trans i18nKey="calculator.steps.0.items.0.items.2">Single apartment building</Trans>
                            </p>
                        </div>
                        <div
                            className={clsx('radio-image-item', buildingInvestment === 4 ? 'checked' : '')}
                            onClick={() => setBuildingInvestment(4)}
                        >
                            <Icon icon={Icons.CalculatorMultipleApartments} width="auto" height={35} />
                            <p>
                                <Trans i18nKey="calculator.steps.0.items.0.items.3">Multiple apartment buildings</Trans>
                            </p>
                        </div>
                    </div>
                </div>
                {(buildingInvestment === 2 || buildingInvestment === 4) && (
                    <div className="container number">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.0.items.1.title">Overall number of buildings</Trans>
                        </p>
                        <div className="number-input">
                            <span onClick={() => {
                                setBuildingOverall(prev => {
                                    const next = prev > 2 ? prev - 1 : 2;

                                    if (buildingUnique > next) {
                                        setBuildingUnique(next);
                                    }

                                    return next;
                                });
                            }}>-</span>
                            <input
                                type="text"
                                value={buildingOverall}
                                onChange={(e) => {
                                    const value = +e.target.value > 2 ? +e.target.value : 2;
                                    setBuildingOverall(value);

                                    if (buildingUnique > value) {
                                        setBuildingUnique(value);
                                    }
                                }}
                            />
                            <span onClick={() => setBuildingOverall(prev => prev + 1)}>+</span>
                        </div>
                    </div>
                )}
                {(buildingInvestment === 2 || buildingInvestment === 4) && (
                    <div className="container number">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.0.items.2.title">Overall number of unique buildings</Trans>
                        </p>
                        <div className="number-input">
                            <span onClick={() => setBuildingUnique(prev => prev > 1 ? prev - 1 : 1)}>-</span>
                            <input
                                type="text"
                                value={buildingUnique}
                                onChange={(e) => {
                                    const value = +e.target.value > 1 ? +e.target.value : 1;
                                    setBuildingUnique(value);

                                    if (buildingOverall < value) {
                                        setBuildingOverall(value);
                                    }
                                }}
                            />
                            <span onClick={() => {
                                setBuildingUnique(prev => {
                                    if (buildingOverall < prev + 1) {
                                        setBuildingOverall(prev + 1);
                                    }

                                    return prev + 1;
                                });
                            }}>+</span>
                        </div>
                    </div>
                )}
                <div className="container radio">
                    <p className="title">
                        <Trans i18nKey="calculator.steps.0.items.3.title">Do you have 3D models of your buildings?</Trans>
                    </p>
                    <div className="radio-items">
                        <div
                            className={clsx('radio-item', buildingModel === 1 ? 'checked' : '')}
                            onClick={() => setBuildingModel(1)}
                        >
                            <Trans i18nKey="calculator.steps.0.items.3.items.0">Yes, I have 3D models of your buildings</Trans>
                        </div>
                        <div
                            className={clsx('radio-item', buildingModel === 2 ? 'checked' : '')}
                            onClick={() => setBuildingModel(2)}
                        >
                            <Trans i18nKey="calculator.steps.0.items.3.items.1">No, I need to create 3D models from the ground up</Trans>
                        </div>
                    </div>
                </div>
                {buildingModel === 1 && (
                    <div className="container radio">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.0.items.4.title">
                                Select the file format utilized for your 3D models
                            </Trans>
                        </p>
                        <div className="radio-items">
                            <div
                                className={clsx('radio-item', buildingModelFormat === 1 ? 'checked' : '')}
                                onClick={() => setBuildingModelFormat(1)}
                            >
                                <Trans i18nKey="calculator.steps.0.items.4.items.0">Building in Unreal Engine</Trans>
                            </div>
                            <div
                                className={clsx('radio-item', buildingModelFormat === 2 ? 'checked' : '')}
                                onClick={() => setBuildingModelFormat(2)}
                            >
                                <Trans i18nKey="calculator.steps.0.items.4.items.1">Building in 3D max, Blender, Corona</Trans>
                            </div>
                            <div
                                className={clsx('radio-item', buildingModelFormat === 3 ? 'checked' : '')}
                                onClick={() => setBuildingModelFormat(3)}
                            >
                                <Trans i18nKey="calculator.steps.0.items.4.items.2">Skechfab, Autocad</Trans>
                            </div>
                        </div>
                    </div>
                )}
                {buildingModel === 2 && (
                    <div className="container radio">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.0.items.5.title">What kind of documentation do you use?</Trans>
                        </p>
                        <div className="radio-items">
                            <div
                                className={clsx('radio-item', buildingModelDocumentation === 1 ? 'checked' : '')}
                                onClick={() => setBuildingModelDocumentation(1)}
                            >
                                <Trans i18nKey="calculator.steps.0.items.5.items.0">2D plans & materials examples</Trans>
                            </div>
                            <div
                                className={clsx('radio-item', buildingModelDocumentation === 2 ? 'checked' : '')}
                                onClick={() => setBuildingModelDocumentation(2)}
                            >
                                <Trans i18nKey="calculator.steps.0.items.5.items.1">2D plans & visualizations</Trans>
                            </div>
                        </div>
                    </div>
                )}
                <div className="container toggle">
                    <p className="title">
                        <span>
                            <Trans i18nKey="calculator.steps.0.items.6.title">
                                Additional 3D amenities inside and outside the building
                            </Trans>
                        </span>
                        <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                    </p>
                    <div
                        className={clsx('toggler', buildingAdditional > 0 ? 'checked' : '')}
                        onClick={() => setBuildingAdditional(prev => prev > 0 ? 0 : 1)}
                    ></div>
                </div>
                {buildingAdditional > 0 && (
                    <div className="container number">
                        <p className="title">
                            <span>
                                <Trans i18nKey="calculator.steps.0.items.7.title">Number of additional features</Trans>
                            </span>
                            <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                        </p>
                        <div className="number-input">
                            <span onClick={() => setBuildingAdditional(prev => prev > 1 ? prev - 1 : 1)}>-</span>
                            <input
                                type="text"
                                value={buildingAdditional}
                                onChange={(e) => {
                                    setBuildingAdditional(+e.target.value > 1 ? +e.target.value : 1);
                                }}
                            />
                            <span onClick={() => setBuildingAdditional(prev => prev + 1)}>+</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="apartments wow fadeInRight">
                <h3>
                    <Trans i18nKey="calculator.steps.1.title">Apartments</Trans>
                </h3>
                <div className="container radio">
                    <p className="title">
                        <Trans i18nKey="calculator.steps.1.items.0.title">
                            Do you require 3D representations of apartments?
                        </Trans>
                    </p>
                    <div className="radio-items">
                        <div
                            className={clsx('radio-item', apartmentsModel === 1 ? 'checked' : '')}
                            onClick={() => setApartmentsModel(1)}
                        >
                            <Trans i18nKey="calculator.steps.1.items.0.items.0">
                                Yes, I want you to create 3D visualizations of the apartments
                            </Trans>
                        </div>
                        <div
                            className={clsx('radio-item', apartmentsModel === 2 ? 'checked' : '')}
                            onClick={() => setApartmentsModel(2)}
                        >
                            <Trans i18nKey="calculator.sterps.1.items.0.items.1">
                                No, I don’t need apartments in 3D
                            </Trans>
                        </div>
                    </div>
                </div>
                {apartmentsModel === 1 && (
                    <div className="container number">
                        <p className="title">
                            <span>
                                <Trans i18nKey="calculator.steps.1.items.1.title">
                                    Overall number of apartments
                                </Trans>
                            </span>
                            <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                        </p>
                        <div className="number-input">
                            <span onClick={() => {
                                setApartmentsOverall(prev => {
                                    const next = prev > 1 ? prev - 1 : 1;

                                    if (apartmentsUnique > next) {
                                        setApartmentsUnique(next);
                                    }

                                    return next;
                                });
                            }}>-</span>
                            <input
                                type="text"
                                value={apartmentsOverall}
                                onChange={(e) => {
                                    const value = +e.target.value > 1 ? +e.target.value : 1;
                                    setApartmentsOverall(value);

                                    if (apartmentsUnique > value) {
                                        setApartmentsUnique(value);
                                    }
                                }}
                            />
                            <span onClick={() => setApartmentsOverall(prev => prev + 1)}>+</span>
                        </div>
                    </div>
                )}
                {apartmentsModel === 1 && (
                    <div className="container number">
                        <p className="title">
                            <span>
                                <Trans i18nKey="calculator.steps.1.items.2.title">Number of unique apartments</Trans>
                            </span>
                            <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                        </p>
                        <div className="number-input">
                            <span onClick={() => setApartmentsUnique(prev => prev > 1 ? prev - 1 : 1)}>-</span>
                            <input
                                type="text"
                                value={apartmentsUnique}
                                onChange={(e) => {
                                    const value = +e.target.value > 1 ? +e.target.value : 1;
                                    setApartmentsUnique(value);

                                    if (apartmentsOverall < value) {
                                        setApartmentsOverall(value);
                                    }
                                }}
                            />
                            <span onClick={() => {
                                setApartmentsUnique(prev => {
                                    if (apartmentsOverall < prev + 1) {
                                        setApartmentsOverall(prev + 1);
                                    }

                                    return prev + 1;
                                });
                            }}>+</span>
                        </div>
                    </div>
                )}
                {apartmentsModel === 1 && (
                    <div className="container number">
                        <p className="title">
                            <span>
                                <Trans i18nKey="calculator.steps.1.items.3.title">
                                    Average number of rooms in the apartment
                                </Trans>
                            </span>
                            <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                        </p>
                        <div className="number-input">
                            <span onClick={() => setApartmentsRooms(prev => prev > 1 ? prev - 1 : 1)}>-</span>
                            <input
                                type="text"
                                value={apartmentsRooms}
                                onChange={(e) => {
                                    setApartmentsRooms(+e.target.value > 1 ? +e.target.value : 1);
                                }}
                            />
                            <span onClick={() => setApartmentsRooms(prev => prev + 1)}>+</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="environment wow fadeInLeft">
                <h3>
                    <Trans i18nKey="calculator.steps.2.title">Environment</Trans>
                </h3>
                <div className="container radio">
                    <p className="title">
                        <Trans i18nKey="calculator.steps.2.items.0.title">Do you have yor own environment model?</Trans>
                    </p>
                    <div className="radio-items">
                        <div
                            className={clsx('radio-item', environmentModel === 1 ? 'checked' : '')}
                            onClick={() => setEnvironmentModel(1)}
                        >
                            <Trans i18nKey="calculator.steps.2.items.0.items.0">
                                Yes, I have my own environment model that I want to use
                            </Trans>
                        </div>
                        <div
                            className={clsx('radio-item', environmentModel === 2 ? 'checked' : '')}
                            onClick={() => setEnvironmentModel(2)}
                        >
                            <Trans i18nKey="calculator.steps.2.items.0.items.1">
                                No, I don’t have model and I want you to create one for me
                            </Trans>
                        </div>
                    </div>
                </div>
                {environmentModel === 1 && (
                    <div className="container radio">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.2.items.1.title">
                                Select the file format utilized for your 3D models
                            </Trans>
                        </p>
                        <div className="radio-items">
                            <div
                                className={clsx('radio-item', environmentModelFormat === 1 ? 'checked' : '')}
                                onClick={() => setEnvironmentModelFormat(1)}
                            >
                                <Trans i18nKey="calculator.steps.2.items.1.items.0">Building in Unreal Engine</Trans>
                            </div>
                            <div
                                className={clsx('radio-item', environmentModelFormat === 2 ? 'checked' : '')}
                                onClick={() => setEnvironmentModelFormat(2)}
                            >
                                <Trans i18nKey="calculator.steps.2.items.1.items.1">Building in 3D max, Blender, Corona</Trans>
                            </div>
                            <div
                                className={clsx('radio-item', environmentModelFormat === 3 ? 'checked' : '')}
                                onClick={() => setEnvironmentModelFormat(3)}
                            >
                                <Trans i18nKey="calculator.steps.2.items.1.items.2">Skechfab, Autocad</Trans>
                            </div>
                        </div>
                    </div>
                )}
                {environmentModel === 2 && (
                    <div className="container radio-image">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.2.items.2.title">
                                How detailed plot do you need?
                            </Trans>
                        </p>
                        <div className="radio-image-items">
                            <div
                                className={clsx('radio-image-item', environmentPlot === 1 ? 'checked' : '')}
                                onClick={() => setEnvironmentPlot(1)}
                            >
                                <Icon icon={Icons.CalculatorPlotOne} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.2.items.0">
                                        Just basic plan & plot plan as a ground
                                    </Trans>
                                </p>
                            </div>
                            <div
                                className={clsx('radio-image-item', environmentPlot === 2 ? 'checked' : '')}
                                onClick={() => setEnvironmentPlot(2)}
                            >
                                <Icon icon={Icons.CalculatorPlotTwo} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.2.items.1">
                                        Plot with grass, couple trees and basic neighborhood
                                    </Trans>
                                </p>
                            </div>
                            <div
                                className={clsx('radio-image-item', environmentPlot === 3 ? 'checked' : '')}
                                onClick={() => setEnvironmentPlot(3)}
                            >
                                <Icon icon={Icons.CalculatorPlotThree} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.2.items.2">
                                        Detailed plot with gardens sidewalks, fences &detailed neighborhood blocks
                                    </Trans>
                                </p>
                            </div>
                            <div
                                className={clsx('radio-image-item', environmentPlot === 4 ? 'checked' : '')}
                                onClick={() => setEnvironmentPlot(4)}
                            >
                                <Icon icon={Icons.CalculatorPlotFour} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.2.items.3">
                                        Fully detailed environment based on photogrammetry
                                    </Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {environmentModel === 2 && (
                    <div className="container radio-image">
                        <p className="title">
                            <Trans i18nKey="calculator.steps.2.items.3.title">
                                How detailed neighborhood do you need?
                            </Trans>
                        </p>
                        <div className="radio-image-items">
                            <div
                                className={clsx('radio-image-item', environmentNeighborhood === 1 ? 'checked' : '')}
                                onClick={() => setEnvironmentNeighborhood(1)}
                            >
                                <Icon icon={Icons.CalculatorNeighborhoodOne} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.3.items.0">
                                        Just basic plan & plot plan as a ground
                                    </Trans>
                                </p>
                            </div>
                            <div
                                className={clsx('radio-image-item', environmentNeighborhood === 2 ? 'checked' : '')}
                                onClick={() => setEnvironmentNeighborhood(2)}
                            >
                                <Icon icon={Icons.CalculatorNeighborhoodTwo} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.3.items.1">
                                        Plot with grass, couple trees and basic neighborhood
                                    </Trans>
                                </p>
                            </div>
                            <div
                                className={clsx('radio-image-item', environmentNeighborhood === 3 ? 'checked' : '')}
                                onClick={() => setEnvironmentNeighborhood(3)}
                            >
                                <Icon icon={Icons.CalculatorNeighborhoodThree} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.3.items.2">
                                        Detailed plot with gardens sidewalks, fences &detailed neighborhood blocks
                                    </Trans>
                                </p>
                            </div>
                            <div
                                className={clsx('radio-image-item', environmentNeighborhood === 4 ? 'checked' : '')}
                                onClick={() => setEnvironmentNeighborhood(4)}
                            >
                                <Icon icon={Icons.CalculatorNeighborhoodFour} width="auto" height={64}/>
                                <p>
                                    <Trans i18nKey="calculator.steps.2.items.3.items.3">
                                        Fully detailed environment based on photogrammetry
                                    </Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="website wow fadeInRight">
                <h3>
                    <Trans i18nKey="calculator.steps.3.title">Website</Trans>
                </h3>
                <div className="container toggle">
                    <p className="title">
                        <span>
                            <Trans i18nKey="calculator.steps.3.items.0.title">Highlight animation</Trans>
                        </span>
                        <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                    </p>
                    <div
                        className={clsx('toggler', websiteHighlight ? 'checked' : '')}
                        onClick={() => setWebsiteHighlight(prev => !prev)}
                    ></div>
                </div>
                <div className="container toggle">
                    <p className="title">
                        <span>
                            <Trans i18nKey="calculator.steps.3.items.1.title">Filtering/Sorting</Trans>
                        </span>
                        <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                    </p>
                    <div
                        className={clsx('toggler', websiteFiltering > 0 ? 'checked' : '')}
                        onClick={() => setWebsiteFiltering(prev => prev > 0 ? 0 : 1)}
                    ></div>
                </div>
                {websiteFiltering > 0 && (
                    <div className="container number">
                        <p className="title">
                            <span>
                                <Trans i18nKey="calculator.steps.3.items.2.title">Number of filters</Trans>
                            </span>
                            <Icon icon={Icons.CalculatorTip} width={18} height={18}/>
                        </p>
                        <div className="number-input">
                            <span onClick={() => setWebsiteFiltering(prev => prev > 1 ? prev - 1 : 1)}>-</span>
                            <input
                                type="text"
                                value={websiteFiltering}
                                onChange={(e) => {
                                    setWebsiteFiltering(+e.target.value > 1 ? +e.target.value : 1);
                                }}
                            />
                            <span onClick={() => setWebsiteFiltering(prev => prev + 1)}>+</span>
                        </div>
                    </div>
                )}
                <div className="container radio">
                    <p className="title">
                        <Trans i18nKey="calculator.steps.3.items.3.title">Hero section animation</Trans>
                    </p>
                    <div className="radio-items">
                        <div
                            className={clsx('radio-item', websiteAnimation === 1 ? 'checked' : '')}
                            onClick={() => setWebsiteAnimation(1)}
                        >
                            <Trans i18nKey="calculator.steps.3.items.3.items.0">Time lapse</Trans>
                        </div>
                        <div
                            className={clsx('radio-item', websiteAnimation === 2 ? 'checked' : '')}
                            onClick={() => setWebsiteAnimation(2)}
                        >
                            <Trans i18nKey="calculator.steps.3.items.3.items.1">Close up</Trans>
                        </div>
                    </div>
                </div>
                <div className="container number">
                    <p className="title">
                        <Trans i18nKey="calculator.steps.3.items.4.title">Languages</Trans>
                    </p>
                    <div className="number-input">
                        <span onClick={() => setWebsiteLanguages(prev => prev > 1 ? prev - 1 : 1)}>-</span>
                        <input
                            type="text"
                            value={websiteLanguages}
                            onChange={(e) => {
                                setWebsiteLanguages(+e.target.value > 1 ? +e.target.value : 1);
                            }}
                        />
                        <span onClick={() => setWebsiteLanguages(prev => prev + 1)}>+</span>
                    </div>
                </div>
            </div>
            <div className="total wow fadeInUp">
                {!isLock && (
                    <div>
                        <span>
                            <Trans i18nKey="calculator.total">Total</Trans>
                        </span>
                        <p>
                            <Trans i18nKey="currency.symbol">$</Trans>
                            {(getBuildingsPrice(false) + getApartmentsPrice(false) + getEnvironmentPrice(false) + getWebsitePrice(false)).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}
                        </p>
                    </div>
                )}
                {isLock && (
                    <button className="white" onClick={() => setLockModal(true)}>
                        <Trans i18nKey="calculator.button">Get the price</Trans>
                    </button>
                )}
            </div>
        </section>
    );
}

export default SectionCalculator;
