import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import SectionCalculator from "../components/SectionCalculator/SectionCalculator";

const Calculator = ({ onChangeLanguage }: { onChangeLanguage: (language: string) => void }) => {
    return (
        <>
            <Header onChangeLanguage={onChangeLanguage} />
            <SectionCalculator />
            <Footer/>
        </>
    );
}

export default Calculator;
