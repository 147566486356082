import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import SectionPromo from "../components/SectionPromo/SectionPromo";
import SectionAbout from "../components/SectionAbout/SectionAbout";
import SectionBenefits from "../components/SectionBenefits/SectionBenefits";
import SectionFeatures from "../components/SectionFeatures/SectionFeatures";
import SectionPricing from "../components/SectionPricing/SectionPricing";
import SectionFaq from "../components/SectionFaq/SectionFaq";
// import SectionBlog from "../components/SectionBlog/SectionBlog";

const Main = ({ onChangeLanguage }: { onChangeLanguage: (language: string) => void }) => {
    return (
        <>
            <Header onChangeLanguage={onChangeLanguage} />
            <SectionPromo />
            <SectionAbout />
            <SectionBenefits />
            <SectionFeatures />
            <SectionPricing />
            <SectionFaq />
            {/*<SectionBlog />*/}
            <Footer/>
        </>
    );
}

export default Main;
