import React from "react";
import { Trans } from "react-i18next";
import "./SectionFeatures.css";

const Calculator = () => {
    return (
        <section id="features" className="features">
            <h2 className="wow fadeInDow">
                <Trans i18nKey="features.title">Custom Features</Trans>
            </h2>
            <p className="description wow fadeInDown">
                <Trans i18nKey="features.description">
                    Wondering if 50k.io is not enough for you? Based on your individual needs, we can provide other
                    advanced apps for you listed below.
                </Trans>
            </p>
            <div className="container">
                <div className="feature wow fadeInLeft">
                    <img src="/img/feature-interior-design.png" alt="Interior design"/>
                    <div>
                        <h4>
                            <Trans i18nKey="features.items.0.title">Interior design</Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="features.items.0.description">
                                Choose the layout style, configure it using basic 3D, and render each view in high quality
                                using Unreal Engine
                            </Trans>
                        </p>
                    </div>
                </div>
                <div className="feature wow fadeInRight">
                    <img src="/img/feature-virtual-reality.png" alt="Virtual Reality"/>
                    <div>
                        <h4>
                            <Trans i18nKey="features.items.1.title">Virtual Reality</Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="features.items.1.description">
                                Property demonstration with 1:1 Virtual Reality (VR) Glasses
                            </Trans>
                        </p>
                    </div>
                </div>
                <div className="feature wow fadeInUp">
                    <img src="/img/feature-software-development.png" alt="Software development"/>
                    <div>
                        <h4>
                            <Trans i18nKey="features.items.2.title">Software development</Trans>
                        </h4>
                        <p>
                            <Trans i18nKey="features.items.2.description">
                                Adapting the platform to meet the unique needs and features of the client, integration
                                with the existing IT environment
                            </Trans>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Calculator;
