import React, { useCallback, useEffect, useState } from "react";
import "./AiChat.css";

interface IAiChat {
    chatUrl: string;
    projectId: string;
    initialMessage: string;
    id?: string;
    className?: string;
    title?: string;
    mode?: 'button' | 'contain';
}
const AiChat = ({ id, className, title, chatUrl, projectId, initialMessage, mode = 'button' }: IAiChat) => {
    const [isShow, setIsShow] = React.useState<boolean>(mode !== 'button');
    const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<{ author: string, message: string }[]>([]);

    const [threadId, setThreadId] = React.useState<string>('');
    const [message, setMessage] = useState<string>('');

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [menu, setMenu] = React.useState<string[]>([]);
    const [suggests, setSuggests] = useState<string[]>([]);

    const onContainerClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const toggleFullScreen = () => {
        setIsFullScreen(prev => {
            if (prev) {
                document.body.style.overflow = 'auto';
            } else {
                document.body.style.overflow = 'hidden';
            }

            return !prev;
        });
    };
    const toggleShow = () => {
        setIsShow(prev => {
            if (isFullScreen) {
                toggleFullScreen();
            }

            return !prev;
        });
    };

    const onMessage = (e: any) => {
        setMessage(e.target.value);
    };
    const onSend = useCallback((e: any, text: string | undefined = undefined, save: boolean = true) => {
        if (e.type === 'keydown' && e.key !== 'Enter') {
            return;
        }

        if (isLoading) {
            return;
        }

        fetch(chatUrl + '/chat-message', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'thread_id': threadId,
                'message': text || message,
                'project_id': projectId
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then((response) => {
                messages.push({
                    author: 'bot',
                    message: response['response']
                });

                setMenu(response['menu']);
                setSuggests(response['suggests']);

                setThreadId(response['thread_id']);
                setMessages(Object.assign([], messages));

                setIsLoading(false);
                setTimeout(() => {
                    const chatWindow = document.querySelector('.AiChatWindowBody');
                    const messages = document.querySelectorAll<HTMLElement>('.AiChatWindowBody .AiChatWindowBodyMessage');

                    if (!chatWindow || messages.length === 0) {
                        return;
                    }

                    chatWindow.scroll({ top: messages[messages.length - 1].offsetTop - 51, behavior: 'smooth' });
                }, 100);
            }).catch(() => {
            messages.push({
                author: 'bot',
                message: 'Sorry i can\'t answer. Try again.'
            });

            setMessages(Object.assign([], messages));
            setIsLoading(false);
        });

        if (save) {
            messages.push({
                author: 'user',
                message: text || message
            });
        }

        setMessages(Object.assign([], messages));

        setSuggests([]);
        setMessage('');

        setIsLoading(true);
        setTimeout(() => {
            const chatWindow = document.querySelector('.AiChatWindowBody');

            if (!chatWindow) {
                return;
            }

            chatWindow.scroll({ top: chatWindow.scrollHeight, behavior: 'smooth' });
        }, 100);
    }, [chatUrl, isLoading, message, messages, projectId, threadId]);

    const onSuggestClick = (suggest: string) => {
        onSend({ type: 'keydown', key: 'Enter' }, suggest);
    };
    const onMenuClick = (event: React.MouseEvent<HTMLLIElement>, menuItem: string) => {
        event.stopPropagation();
        onSend({ type: 'keydown', key: 'Enter' }, menuItem);
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (!isShow || messages.length !== 0) {
            return;
        }

        onSend({ type: 'keydown', key: 'Enter' }, initialMessage, false);
    }, [initialMessage, isShow, messages, onSend]);
    useEffect(() => {
        if (!isShow) {
            return;
        }

        const chatWindow = document.querySelector('.AiChatWindowBody');

        if (!chatWindow) {
            return;
        }

        chatWindow.scroll({ top: chatWindow.scrollHeight, behavior: 'smooth' });
    }, [isShow]);

    return (
        <div id={id} className={`AiChat ${mode} ${className || ''}`} onClick={onContainerClick}>
            {!isShow && (
                <button className="AiChatButton" onClick={toggleShow}>
                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16,19a6.9908,6.9908,0,0,1-5.833-3.1287l1.666-1.1074a5.0007,5.0007,0,0,0,8.334,0l1.666,1.1074A6.9908,6.9908,0,0,1,16,19Z"/>
                        <path d="M20,8a2,2,0,1,0,2,2A1.9806,1.9806,0,0,0,20,8Z"/>
                        <path d="M12,8a2,2,0,1,0,2,2A1.9806,1.9806,0,0,0,12,8Z"/>
                        <path d="M17.7358,30,16,29l4-7h6a1.9966,1.9966,0,0,0,2-2V6a1.9966,1.9966,0,0,0-2-2H6A1.9966,1.9966,0,0,0,4,6V20a1.9966,1.9966,0,0,0,2,2h9v2H6a3.9993,3.9993,0,0,1-4-4V6A3.9988,3.9988,0,0,1,6,2H26a3.9988,3.9988,0,0,1,4,4V20a3.9993,3.9993,0,0,1-4,4H21.1646Z"/>
                    </svg>
                </button>
            )}
            {isShow && (
                <div className={`AiChatWindow ${isFullScreen ? 'AiChatFullScreen' : ''}`}>
                    <div className="AiChatWindowHeader">
                        <button onClick={toggleFullScreen}>
                            <svg width="18" height="18" viewBox="0 0 32 32" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                                <path d="M4 12 L4 4 12 4 M20 4 L28 4 28 12 M4 20 L4 28 12 28 M28 20 L28 28 20 28" />
                            </svg>
                        </button>
                        <p>{title || 'AI Chat Bot'}</p>
                        <button onClick={toggleShow}>
                            <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.9 13.308L9.5 9.708L13.1 13.308L13.808 12.6L10.208 9L13.808 5.4L13.1 4.692L9.5 8.292L5.9 4.692L5.192 5.4L8.792 9L5.192 12.6L5.9 13.308ZM9.503 18C8.259 18 7.089 17.764 5.993 17.292C4.89767 16.8193 3.94467 16.178 3.134 15.368C2.32333 14.558 1.68167 13.606 1.209 12.512C0.736333 11.418 0.5 10.2483 0.5 9.003C0.5 7.75767 0.736333 6.58767 1.209 5.493C1.681 4.39767 2.32133 3.44467 3.13 2.634C3.93867 1.82333 4.891 1.18167 5.987 0.709C7.083 0.236333 8.253 0 9.497 0C10.741 0 11.911 0.236333 13.007 0.709C14.1023 1.181 15.0553 1.82167 15.866 2.631C16.6767 3.44033 17.3183 4.39267 17.791 5.488C18.2637 6.58333 18.5 7.753 18.5 8.997C18.5 10.241 18.264 11.411 17.792 12.507C17.32 13.603 16.6787 14.556 15.868 15.366C15.0573 16.176 14.1053 16.8177 13.012 17.291C11.9187 17.7643 10.749 18.0007 9.503 18Z"/>
                            </svg>
                        </button>
                    </div>
                    <div className="AiChatWindowBody">
                        {messages.map((p, index) => (
                            <div key={index}
                                 className={`AiChatWindowBodyMessage AiChatWindowBodyMessage${p.author === 'bot' ? 'Bot' : 'User'}`}>
                                {p.message}
                            </div>
                        ))}
                        {isLoading && (
                            <div className="AiChatWindowBodyMessage AiChatWindowBodyMessageBot">
                                <svg>
                                    <circle cx="10" cy="5" r="3" />
                                    <circle cx="20" cy="5" r="3" />
                                    <circle cx="30" cy="5" r="3" />
                                </svg>
                            </div>
                        )}
                    </div>
                    {suggests.length !== 0 && (
                        <div className="AiChatWindowSuggests">
                            {suggests.map(suggest => (
                                <div onClick={() => onSuggestClick(suggest)}>{suggest}</div>
                            ))}
                        </div>
                    )}
                    <div className="AiChatWindowFooter">
                        {menu.length > 0 && (
                            <div className="AiChatWindowMenu" onClick={() => setIsMenuOpen(prev => !prev)}>
                                <svg viewBox="0 0 276.167 276.167" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <g>
                                            <path d="M33.144,2.471C15.336,2.471,0.85,16.958,0.85,34.765s14.48,32.293,32.294,32.293s32.294-14.486,32.294-32.293
                                                S50.951,2.471,33.144,2.471z"/>
                                            <path d="M137.663,2.471c-17.807,0-32.294,14.487-32.294,32.294s14.487,32.293,32.294,32.293c17.808,0,32.297-14.486,32.297-32.293
                                                S155.477,2.471,137.663,2.471z"/>
                                            <path d="M243.873,67.059c17.804,0,32.294-14.486,32.294-32.293S261.689,2.471,243.873,2.471s-32.294,14.487-32.294,32.294
                                                S226.068,67.059,243.873,67.059z"/>
                                            <path d="M32.3,170.539c17.807,0,32.297-14.483,32.297-32.293c0-17.811-14.49-32.297-32.297-32.297S0,120.436,0,138.246
                                                C0,156.056,14.493,170.539,32.3,170.539z"/>
                                            <path d="M136.819,170.539c17.804,0,32.294-14.483,32.294-32.293c0-17.811-14.478-32.297-32.294-32.297
                                                c-17.813,0-32.294,14.486-32.294,32.297C104.525,156.056,119.012,170.539,136.819,170.539z"/>
                                            <path d="M243.038,170.539c17.811,0,32.294-14.483,32.294-32.293c0-17.811-14.483-32.297-32.294-32.297
                                                s-32.306,14.486-32.306,32.297C210.732,156.056,225.222,170.539,243.038,170.539z"/>
                                            <path d="M33.039,209.108c-17.807,0-32.3,14.483-32.3,32.294c0,17.804,14.493,32.293,32.3,32.293s32.293-14.482,32.293-32.293
                                                S50.846,209.108,33.039,209.108z"/>
                                            <path d="M137.564,209.108c-17.808,0-32.3,14.483-32.3,32.294c0,17.804,14.487,32.293,32.3,32.293
                                                c17.804,0,32.293-14.482,32.293-32.293S155.368,209.108,137.564,209.108z"/>
                                            <path d="M243.771,209.108c-17.804,0-32.294,14.483-32.294,32.294c0,17.804,14.49,32.293,32.294,32.293
                                                c17.811,0,32.294-14.482,32.294-32.293S261.575,209.108,243.771,209.108z"/>
                                        </g>
                                    </g>
                                </svg>
                                {isMenuOpen && (
                                    <ul className="AiChatWindowMenuContent">
                                        {menu.map((p, index) => (
                                            <li
                                                key={index}
                                                onClick={(event) => onMenuClick(event, p)}
                                            >
                                                {p}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        <input type="text" placeholder="Type a message..." value={message} onChange={onMessage}
                               onKeyDown={onSend}/>
                        <button onClick={onSend}>
                            <svg width="9" height="8" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.647546 7.95998L8.71874 4.43328C8.80218 4.39705 8.87332 4.33661 8.92333 4.25949C8.97334 4.18237 9 4.09196 9 3.99951C9 3.90707 8.97334 3.81666 8.92333 3.73954C8.87332 3.66241 8.80218 3.60198 8.71874 3.56575L0.647546 0.0390452C0.577658 0.00797177 0.50128 -0.00487695 0.425303 0.00165795C0.349326 0.00819286 0.27614 0.0339058 0.212348 0.0764772C0.148556 0.119049 0.0961645 0.177139 0.0599002 0.245508C0.0236359 0.313877 0.00463967 0.390373 0.00462532 0.468096L0 2.64164C0 2.87738 0.171137 3.08012 0.402403 3.10841L6.93799 3.99951L0.402403 4.8859C0.171137 4.91891 0 5.12165 0 5.35739L0.00462532 7.53093C0.00462532 7.86568 0.342274 8.09671 0.647546 7.95998Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AiChat;
