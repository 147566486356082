import React from "react";
import AiChat from "./AiChat/AiChat";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Section from "../components/Section/Section";

interface ChatBotProps {
    onChangeLanguage: (language: string) => void;
}
const ChatBot = (props: ChatBotProps) => {
    return (
        <>
            <Header onChangeLanguage={props.onChangeLanguage} />
            <Section>
                <div style={{ height: '600px' }}>
                    <AiChat
                        chatUrl="https://chat.50k.io"
                        projectId="d0de36ac-ea3e-4cb0-805c-430468453dcf"
                        initialMessage="Write welcome message."
                        mode="contain"
                    />
                </div>
            </Section>
            <Footer/>
        </>
    );
}

export default ChatBot;
