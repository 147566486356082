import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    }
);

let courses = [];
const currencies = {
    'en': 'USD',
    'kz': 'KZT',
    'ru': 'RUB',
    'tr': 'TRY'
};

export const initCourses = async () => {
    courses = await (await fetch('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json')).json();
};
export const i18nCurrency = (value, format = true) => {
    if (courses.length === 0 || i18n.language === 'en') {
        return value;
    }

    const usdCourse = courses.find(p => p['cc'] === 'USD');

    if (!usdCourse) {
        return value;
    }

    const uahPrice = value * usdCourse['rate'];
    const currencyCourse = courses.find(p => p['cc'] === currencies[i18n.language]);

    if (!currencyCourse) {
        return value;
    }

    const newValue = Math.round(uahPrice / currencyCourse['rate']);

    if (!format) {
        return newValue - newValue % 100;
    }
    return (newValue - newValue % 100).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
};

export default i18n;