import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Icon, Icons } from "../../components/Icon/Icon";
import "./Footer.css";

const Footer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

    const scrollToAnchor = (anchor: string, location: string | null = '/') => {
        if (location) {
            navigate(location);
        }

        setTimeout(() => {
            document.body.className = '';
            window.scrollTo(0, document.getElementById(anchor)!.offsetTop - 106);
        }, 300);
    };

    const onContact = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const firstName = event.target[0].value;
        const lastName = event.target[1].value;
        const phone = event.target[2].value;
        const email = event.target[3].value;
        const text = event.target[4].value;

        const url = 'https://api.telegram.org/bot6795818259:AAG5oLXb9v33iB9RvwfDd0iyp9bR61_hGis/sendMessage?chat_id=-1002007602712&parse_mode=html&text=';
        const message = `<strong>New lead from landing</strong>\n\n<b>First name:</b> ${firstName}\n<b>Last name:</b> ${lastName}\n<b>Phone:</b> ${phone}\n<b>Email:</b> ${email}\n<b>Text:</b> ${text}`;

        await fetch(url + encodeURIComponent(message));
        setIsSubmitted(true);
    };

    return (
        <footer>
            <div id="contact" className="contact">
                <div className="container">
                    {isSubmitted && (
                        <div className="submitted">
                            <Icon icon={Icons.AboutCheck} width="100%" height={50} />
                            <p>
                                <Trans i18nKey="footer.contact.submitted">
                                    Your message has been sent. We will reply to you as quickly as possible
                                </Trans>
                            </p>
                        </div>
                    )}
                    <h2 className="wow fadeInDown">
                        <Trans i18nKey="footer.contact.title">Let's talk!</Trans>
                    </h2>
                    <form onSubmit={onContact}>
                        <div className="fields">
                            <input
                                className="wow fadeInLeft"
                                type="text"
                                name="firstName"
                                placeholder={t("footer.contact.firstName")}
                                required
                                pattern="[A-Za-z]{1,}"
                                title="Only letters accepted"
                            />
                            <input
                                className="wow fadeInRight"
                                type="test"
                                name="lastName"
                                placeholder={t("footer.contact.lastName")}
                                required
                                pattern="[A-Za-z]{1,}"
                                title="Only letters accepted"
                            />
                        </div>
                        <div className="fields">
                            <input
                                className="wow fadeInLeft"
                                type="tel"
                                name="phone"
                                placeholder={t("footer.contact.phone")}
                                required
                                pattern="[0-9]{1,}"
                                title="Only numbers accepted"
                            />
                            <input
                                className="wow fadeInRight"
                                type="email"
                                name="email"
                                placeholder={t("footer.contact.email")}
                                required
                            />
                        </div>
                        <textarea
                            className="wow fadeInUp"
                            name="text"
                            rows={4}
                            placeholder={t("footer.contact.text")}
                            required
                        ></textarea>
                        <div className="buttons wow fadeInUp">
                            <button type="submit" className="white">
                                <Trans i18nKey="footer.contact.button">Send</Trans>
                            </button>
                            <p>
                                <Trans i18nKey="footer.contact.agreement">
                                    I hereby authorize the transfer of my information, using this form, to 1@50k.io, for
                                    assessment by authorized personnel. We pledge to promptly address your inquiry. If this
                                    form was mistakenly submitted or you wish to withdraw your information, please notify
                                    us via email at 1@50k.io. Rest assured, we maintain rigorous privacy protocols and will
                                    not share your data with third parties or send you unwanted communications
                                </Trans>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
            <div className="footer">
                <div className="container">
                    <div className="left">
                        <Icon icon={Icons.Logo} width={210} height={55} />
                        <nav aria-label="Menu">
                            <ul>
                                <li onClick={() => scrollToAnchor('about')}>
                                    <Trans i18nKey="footer.menu.about">About</Trans>
                                </li>
                                <li onClick={() => scrollToAnchor('pricing')}>
                                    <Trans i18nKey="footer.menu.pricing">Pricing</Trans>
                                </li>
                                <li onClick={() => scrollToAnchor('faq')}>
                                    <Trans i18nKey="footer.menu.faq">FAQ</Trans>
                                </li>
                                {/*<li onClick={() => scrollToAnchor('blog')}>Blog</li>*/}
                                <li onClick={() => scrollToAnchor('calculator', '/calculator')}>
                                    <Trans i18nKey="footer.menu.calculator">Calculator</Trans>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="right">
                        <div>
                            <span>
                                <Trans i18nKey="footer.menu.write">Write us</Trans>
                            </span>
                            <b>
                                <Trans i18nKey="footer.menu.email">1@50k.io</Trans>
                            </b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="footer.menu.call">Call us</Trans>
                            </span>
                            <b>
                                <Trans i18nKey="footer.menu.phone">+90 505 833 4179</Trans>
                            </b>
                        </div>
                        <div>
                            <span>
                                <Trans i18nKey="footer.menu.address">Address</Trans>
                            </span>
                            <b>
                                <Trans i18nKey="footer.menu.location">Turkey, Istanbul, Libadiye Cd. D:82F</Trans>
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
